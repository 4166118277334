import { useWindowWidth } from '@react-hook/window-size';
import * as Sentry from '@sentry/gatsby';
import axios from 'axios';
import cx from 'classnames';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Rellax from 'rellax';
import srcLogotype from '../../assets/logotype-white2.svg';
import Button from '../../components/Form/Button';
import Layout from '../../components/Layout/Layout';
import { contactURL, safe } from '../../utils';
import useImageQuery from '../../utils/use-image-query';
import styles from './register.module.scss';

export const merchantRegisterPageQuery = graphql`
  query MerchantRegisterPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HEADER_HEIGHT = 64;

const PLATFORM_OPTIONS = ['Shopify', 'Stores.jp', 'EC-Cube', 'Base'];
const CATEGORY_OPTIONS = ['retail', 'digital', 'service', 'giftcard'];
const LOWER_CASED_PLATFORM_OPTIONS = PLATFORM_OPTIONS.map((p) =>
  p.toLowerCase()
);

const HeroFigure = () => {
  const winWidth = useWindowWidth();
  // const isMobile = winWidth < 768;

  const refParallaxMerchant1 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant2 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant3 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant4 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant5 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant6 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant7 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant8 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant9 = useRef<HTMLImageElement>(null);
  const refParallaxMerchant10 = useRef<HTMLImageElement>(null);

  const {
    srcMerchantBlock1,
    srcMerchantBlock2,
    srcMerchantBlock3,
    srcMerchantBlock4,
    srcMerchantBlock5,
    srcMerchantBlock6,
    srcMerchantBlock7,
    srcMerchantBlock8,
    srcMerchantBlock9,
    srcMerchantBlock10,
  } = useImageQuery();

  useEffect(() => {
    refParallaxMerchant1.current &&
      new Rellax(refParallaxMerchant1.current, { speed: 1 });
    refParallaxMerchant2.current &&
      new Rellax(refParallaxMerchant2.current, { speed: 3 });
    refParallaxMerchant3.current &&
      new Rellax(refParallaxMerchant3.current, { speed: 2 });
    refParallaxMerchant4.current &&
      new Rellax(refParallaxMerchant4.current, { speed: 4 });
    refParallaxMerchant5.current &&
      new Rellax(refParallaxMerchant5.current, { speed: 1 });
    refParallaxMerchant6.current &&
      new Rellax(refParallaxMerchant6.current, { speed: 3 });
    refParallaxMerchant7.current &&
      new Rellax(refParallaxMerchant7.current, { speed: 2 });
    refParallaxMerchant8.current &&
      new Rellax(refParallaxMerchant8.current, { speed: 4 });
    refParallaxMerchant9.current &&
      new Rellax(refParallaxMerchant9.current, { speed: 5 });
    refParallaxMerchant10.current &&
      new Rellax(refParallaxMerchant10.current, { speed: 1 });
  }, []);

  return (
    <div className={styles.heroFigure}>
      <div className={cx(styles.merchantBlock, styles.block1)}>
        <div ref={refParallaxMerchant1}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock1.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block2)}>
        <div ref={refParallaxMerchant2}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock2.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block3)}>
        <div ref={refParallaxMerchant3}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock3.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block4)}>
        <div ref={refParallaxMerchant4}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock4.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block5)}>
        <div ref={refParallaxMerchant5}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock5.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block6)}>
        <div ref={refParallaxMerchant6}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock6.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block7)}>
        <div ref={refParallaxMerchant7}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock7.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block8)}>
        <div ref={refParallaxMerchant8}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock8.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block9)}>
        <div ref={refParallaxMerchant9}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock9.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className={cx(styles.merchantBlock, styles.block10)}>
        <div ref={refParallaxMerchant10}>
          <GatsbyImage
            alt=""
            image={srcMerchantBlock10.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  );
};

const MerchantRegisterPage: React.FC = () => {
  const { originalPath, i18n, navigate } = useI18next();
  const { t } = useTranslation();
  const [previewing, setPreviewing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [department, setDepartment] = useState('');
  const [title, setTitle] = useState('');
  const [brand, setBrand] = useState('');
  const [website, setWebsite] = useState('');
  const [annualSale, setAnnualSale] = useState('');
  const [categories, setCategories]: [Set<string>, Function] = useState(
    new Set()
  );
  const [platforms, setPlatforms]: [Set<string>, Function] = useState(
    new Set()
  );
  const [merchandiseDesc, setMerchandiseDesc] = useState('');
  const [offerGiftCard, setOfferGiftCard] = useState<boolean>();
  const [showOtherCategory, setShowOtherCategory] = useState(false);
  const [showOtherPlatform, setShowOtherPlatform] = useState(false);
  const [otherPlatform, setOtherPlatform] = useState('');
  const filteredCategories = [...categories].filter((c) =>
    CATEGORY_OPTIONS.includes(c)
  );
  const filteredPlatforms = [...platforms].filter((p) =>
    LOWER_CASED_PLATFORM_OPTIONS.includes(p)
  );
  const [otherCategory, setOtherCategory] = useState('');

  const canSubmit =
    name &&
    email &&
    website &&
    merchandiseDesc &&
    brand &&
    (filteredCategories.length || otherCategory.trim()) &&
    (showOtherCategory ? otherCategory.trim() : true) &&
    annualSale &&
    (filteredPlatforms.length || otherPlatform.trim()) &&
    (showOtherPlatform ? otherPlatform.trim() : true) &&
    typeof offerGiftCard !== 'undefined';

  const onAddCategory = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    if (!CATEGORY_OPTIONS.includes(target.value)) {
      return false;
    }

    const selectedCategories: Set<string> = new Set(categories);

    if (target.checked) {
      selectedCategories.add(target.value);
    } else {
      selectedCategories.delete(target.value);
    }

    setCategories(selectedCategories);
  };

  const onAddPlatform = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    if (!LOWER_CASED_PLATFORM_OPTIONS.includes(target.value)) {
      return false;
    }

    const selectedPlatforms: Set<string> = new Set(platforms);

    if (target.checked) {
      selectedPlatforms.add(target.value);
    } else {
      selectedPlatforms.delete(target.value);
    }

    setPlatforms(selectedPlatforms);
  };

  const onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    if (!canSubmit || submitting) {
      return false;
    }

    event.preventDefault();

    setSubmitting(true);

    const sanitizedOtherCategory = otherCategory.trim();
    const sanitizedOtherPlatform = otherPlatform.trim();

    const payload = {
      email: safe(email),
      name: safe(name),
      company: safe(companyName),
      department: safe(department),
      title: safe(title),
      ecSiteUrl: contactURL(safe(website)),
      annualSale: safe(annualSale),
      brandName: brand,
      category: sanitizedOtherCategory
        ? filteredCategories.concat(sanitizedOtherCategory).join(',')
        : filteredCategories.join(','),
      productDescription: merchandiseDesc,
      offerGiftCard,
      platform: sanitizedOtherPlatform
        ? filteredPlatforms.concat(sanitizedOtherPlatform).join(',')
        : filteredPlatforms.join(','),
      pageLanguage: i18n.language,
    };

    const endpoint =
      process.env.GATSBY_MERCHANT_REGISTER_URL ||
      '/api/merchant/onboarding/application';

    axios
      .post(endpoint, payload)
      .then(() => {
        navigate('/merchant/register-submitted/', { replace: true });
      })
      .catch((error) => {
        Sentry.captureException(error);
        setSubmitting(false);
        setError('unexpected_error');
      });

    return false;
  };

  useLayoutEffect(() => {
    if (typeof previewing === 'boolean') {
      window.scrollTo(
        0,
        previewing
          ? 1 // 0 didn't work sometimes
          : (document.getElementById('form')?.offsetTop || 0) - HEADER_HEIGHT
      );
    }
  }, [previewing]);

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      wrapperClassName={styles.mainWrapper}
    >
      {!previewing && (
        <>
          <section id="hero" className={styles.heroContainer}>
            <div className={styles.box}>
              <div className={cx(styles.content)}>
                <h1>
                  <img src={srcLogotype} alt="Smartpay" height={80} />
                  <br />
                  {
                    <Trans
                      i18nKey="merchant-register-title"
                      components={{ span: <span /> }}
                    />
                  }
                </h1>
              </div>
            </div>
            <HeroFigure />
          </section>

          <div className={styles.main}>
            <p
              className={styles.desc}
              dangerouslySetInnerHTML={{ __html: t('get-started-description') }}
            />
            {/* Name */}
            <form id="form" className={styles.form} onSubmit={onSubmit}>
              <div className={styles.row}>
                <label htmlFor="name">
                  {t('contact-form-name')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(event) => setName(event.currentTarget.value)}
                  placeholder="田中　かおる"
                  required
                />
              </div>
              {/* Email */}
              <div className={styles.row}>
                <label htmlFor="email">
                  {t('contact-form-email')} <span>*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  placeholder="hello@smartpay.co"
                  required
                />
              </div>
              {/* Company Name */}
              <div className={styles.row}>
                <label htmlFor="company_name">
                  {t('contact-form-company-name')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="company_name"
                  name="company-name"
                  value={companyName}
                  placeholder={t('contact-form-company-name-placeholder')}
                  onChange={(event) =>
                    setCompanyName(event.currentTarget.value)
                  }
                  required
                />
              </div>
              {/* Department */}
              <div className={styles.row}>
                <label htmlFor="department">
                  {t('contact-form-department')}
                </label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={department}
                  placeholder={t('contact-form-department-placeholder')}
                  onChange={(event) => setDepartment(event.currentTarget.value)}
                  required
                />
              </div>
              {/* Title */}
              <div className={styles.row}>
                <label>{t('contact-form-title')}</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  placeholder={t('contact-form-title-placeholder')}
                  onChange={(event) => setTitle(event.currentTarget.value)}
                  required
                />
              </div>
              {/* Brand */}
              <div className={styles.row}>
                <label htmlFor="brand">
                  {t('contact-form-brand')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="brand"
                  name="brand"
                  value={brand}
                  placeholder="Smartpay"
                  onChange={(event) => setBrand(event.currentTarget.value)}
                  required
                />
              </div>
              {/* Website */}
              <div className={styles.row}>
                <label htmlFor="website">
                  {t('contact-form-website')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={website}
                  placeholder="www.smartpay.co"
                  onChange={(event) => setWebsite(event.currentTarget.value)}
                  required
                />
              </div>
              {/* Annual Sale */}
              <div className={styles.row}>
                <label htmlFor="annual_sale">
                  {t('contact-form-annual-sale')} <span>*</span>
                </label>
                <input
                  type="text"
                  id="annual_sale"
                  name="annual-sale"
                  value={annualSale}
                  placeholder="￥"
                  onChange={(event) => setAnnualSale(event.currentTarget.value)}
                  required
                />
              </div>
              {/* Category */}
              <div className={styles.row}>
                <label>
                  {t('contact-form-category')} <span>*</span>
                </label>
                {CATEGORY_OPTIONS.map((p, i) => (
                  <fieldset className={styles.checkbox} key={i}>
                    <input
                      type="checkbox"
                      id={`category_${p}`}
                      value={p}
                      name="category"
                      onChange={onAddCategory}
                      checked={categories.has(p)}
                    />
                    <label htmlFor={`category_${p}`}>
                      {t(`contact-form-option-${p}` as const)}
                    </label>
                  </fieldset>
                ))}
                <fieldset className={styles.checkbox}>
                  <input
                    type="checkbox"
                    id="category_others"
                    name="category"
                    onChange={(event) => {
                      setShowOtherCategory(!showOtherCategory);

                      if (!event.currentTarget.checked) {
                        setOtherCategory('');
                        categories.delete(otherCategory);
                        setCategories(categories);
                      }
                    }}
                    checked={showOtherCategory}
                  />
                  <label htmlFor="category_others">
                    {t('contact-form-option-others')}
                  </label>
                </fieldset>
              </div>
              {/* Other Category */}
              {showOtherCategory && (
                <div className={styles.row}>
                  <label htmlFor="other_category">
                    {t('contact-form-other-category')} <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="other_category"
                    name="other-category"
                    value={otherCategory}
                    placeholder={t('contact-form-other-category-placeholder')}
                    onChange={(event) =>
                      setOtherCategory(event.currentTarget.value)
                    }
                    required
                  />
                </div>
              )}
              {/* Merchandise description */}
              <div className={styles.row}>
                <label htmlFor="merchandise_desc">
                  {t('contact-form-merchandise-desc')} <span>*</span>
                </label>
                <textarea
                  id="merchandise_desc"
                  name="merchandise-desc"
                  value={merchandiseDesc}
                  placeholder={t('contact-form-merchandise-desc-placeholder')}
                  onChange={(event) => setMerchandiseDesc(event.target.value)}
                  required
                />
              </div>
              {/* Gift card offered? */}
              <div className={styles.row}>
                <label htmlFor="gift_card_offered">
                  {t('contact-form-gift-card-offered')} <span>*</span>
                </label>
                <fieldset className={styles.radio}>
                  <input
                    type="radio"
                    id="gift_card_offered"
                    value="true"
                    name="gift_card_offered"
                    onChange={() => setOfferGiftCard(true)}
                    required
                    checked={offerGiftCard}
                  />
                  <label htmlFor="gift_card_offered">
                    {t('contact-form-option-offered')}
                  </label>
                  <input
                    type="radio"
                    id="gift_card_not_offered"
                    value="false"
                    name="gift_card_offered"
                    onChange={() => setOfferGiftCard(false)}
                    required
                    checked={offerGiftCard === false}
                  />
                  <label htmlFor="gift_card_not_offered">
                    {t('contact-form-option-not-offered')}
                  </label>
                </fieldset>
              </div>
              {/* Platform */}
              <div className={styles.row}>
                <label htmlFor="platform">
                  {t('contact-form-platform')} <span>*</span>
                </label>
                {PLATFORM_OPTIONS.map((p, i) => (
                  <fieldset className={styles.checkbox} key={i}>
                    <input
                      type="checkbox"
                      id={`platform_${p}`}
                      value={p.toLowerCase()}
                      name="platform"
                      onChange={onAddPlatform}
                      checked={platforms.has(p.toLowerCase())}
                    />
                    <label htmlFor={`platform_${p}`}>{p}</label>
                  </fieldset>
                ))}
                <fieldset className={styles.checkbox}>
                  <input
                    type="checkbox"
                    id="platform_others"
                    name="platform"
                    onChange={(event) => {
                      setShowOtherPlatform(!showOtherPlatform);

                      if (!event.currentTarget.checked) {
                        setOtherPlatform('');
                        platforms.delete(otherPlatform);
                        setPlatforms(platforms);
                      }
                    }}
                    checked={showOtherPlatform}
                  />
                  <label htmlFor="platform_others">
                    {t('contact-form-option-others')}
                  </label>
                </fieldset>
              </div>
              {/* Other Platform */}
              {showOtherPlatform && (
                <div className={styles.row}>
                  <label htmlFor="other_platform">
                    {t('contact-form-other-platform')} <span>*</span>
                  </label>
                  <input
                    type="text"
                    id="other_platform"
                    name="other-platform"
                    value={otherPlatform}
                    placeholder={t('contact-form-other-platform-placeholder')}
                    onChange={(event) =>
                      setOtherPlatform(event.currentTarget.value)
                    }
                    required
                  />
                </div>
              )}

              <Button
                type="button"
                disabled={!canSubmit}
                loading={submitting}
                label={t('send')}
                onClick={() => setPreviewing(true)}
              />
              {error && (
                <div className={styles.errorWrapper}>
                  <p id="error_message">{t('unexpected-error')}</p>
                </div>
              )}
            </form>
          </div>
        </>
      )}
      {previewing && (
        <form id="preview" className={styles.preview} onSubmit={onSubmit}>
          <header>
            <h1>
              <Trans
                i18nKey="merchant-register-confirmation-title"
                components={{ span: <span /> }}
              />
            </h1>
            <p>{t('merchant-register-confirmation-desc')}</p>
          </header>
          <div className={styles.row}>
            <label>{t('contact-form-name')}</label>
            <span>{name}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-email')}</label>
            <span>{email}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-company-name')}</label>
            <span>{companyName}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-department')}</label>
            <span>{department}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-title')}</label>
            <span>{title}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-brand')}</label>
            <span>{brand}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-website')}</label>
            <span>{website}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-annual-sale')}</label>
            <span>{annualSale}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-category')}</label>
            <span>{`${(otherCategory.trim()
              ? filteredCategories.concat(otherCategory.trim())
              : filteredCategories
            )
              .map((c) => {
                if (CATEGORY_OPTIONS.indexOf(c) >= 0) {
                  return t(`contact-form-option-${c}` as const);
                }

                return c;
              })
              .join(', ')}`}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-merchandise-desc')}</label>
            <span>{merchandiseDesc}</span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-gift-card-offered')}</label>
            <span>
              {offerGiftCard
                ? t('contact-form-option-offered')
                : t('contact-form-option-not-offered')}
            </span>
          </div>
          <div className={styles.row}>
            <label>{t('contact-form-platform')}</label>
            <span>{`${(otherPlatform.trim()
              ? filteredPlatforms.concat(otherPlatform.trim())
              : filteredPlatforms
            ).join(', ')}`}</span>
          </div>

          <div className={styles.actions}>
            <Button
              type="button"
              className={styles.secondary}
              loading={submitting}
              label={t('edit')}
              onClick={() => setPreviewing(false)}
            />
            <Button type="submit" loading={submitting} label={t('send')} />
          </div>
        </form>
      )}
    </Layout>
  );
};

export default MerchantRegisterPage;
